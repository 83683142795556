@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap'); */

/* @layer base {
    body {
        @apply font-[Raleway]
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
} */

/* .content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
} */

body {
  background: #f2f2f2;
  font-family: "Lato", sans-serif !important;
}
